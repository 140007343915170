import React, {Component} from 'react';
import _ from 'lodash';

import { SlideConfirm } from "@cargo/ui-kit/slide-confirm/slide-confirm"; 
import { ProcessingAnimation } from "@cargo/ui-kit/processing/processing"; 
import { HotKeyManager } from "@cargo/ui-kit/hotkey/hotkey-manager";


import './alert.scss';

export const AlertContext = React.createContext({
    header: null,
    message: null,
    slideMessage: null,
    placeholder: null,
    existingValue: null,
    type: 'alert', // dialog, confirm, custom
    children: null,
    customElement: null,
    preventCancel: false,
    preventClose: false,
    cancelButton: false,
    overCart: false,
    checkBoxConfirmation: null,
    checkBoxConfirmationValue: false,
    confirmButton: 'Yes',
    denyButton: 'No',
    maxInputLength: null,
    validateInput: () => true,
    onOpen: () => null,
    onConfirm: () => null,
    onCancel: () => null,
    onDeny: () => null,
    onClose: () => null,
});


export class Alert extends Component {

	constructor(props) {

		super(props);

		this.modalDefaults = {
		    header: null,
		    htmlHeader: null,
		    message: null,
		    slideMessage: null,
		    htmlMessage: null,
		    type: 'alert', // dialog, confirm, custom
		    children: null,
		    customElement: null,
		    preventCancel: false,
		    preventClose: false,
		    confirmButton: 'OK',
		    denyButton: 'Cancel',
		    cancelButton: false,
		    overCart: false,
		    checkBoxConfirmation: null,
		    checkBoxConfirmationValue: false,
		    frontendData: null,
		    maxInputLength: null,
		    closeManually: false,
		    validateInput: () => true,
		    onOpen: () => null,
		    onConfirm: () => null,
		    onCancel: () => null,
		    onDeny: () => null,
		    onClose: () => null,     
		}

		this.state = {
		    modalOpen: false,
		    animate: false,
		    processConfirm: false,
		    ...this.modalDefaults
		}


		this.overlayRef = React.createRef();
		this.inputRef = React.createRef();

		this.transitioning  = false;
		this.ignoreUnmount  = false;
		this.internalHotKeySet = false;
		this.alterEventExecution = true;
		this.clickStartedInModal = false;
		this.unregisterEscapeHotKey = null;
		this.unregisterEnterHotKey = null;

	}

	openModal = (opts) => {

		if( this.transitioning  || this.state.modalOpen || this.state.animate ){ return }

		// remove focus from potential inputs so they won't respond to keystrokes
		document.activeElement?.blur();

		this.transitioning = true;
		const options = _.defaults(opts, this.modalDefaults);

		// Create and dispatch custom event letting other components know an alert modal has been opened
		const alertOpenedEvent = new Event('openAlertModal');
		window.dispatchEvent( alertOpenedEvent );

        if ( options.onOpen){
            options.onOpen();
        }

        if( window.__c3_admin__ === true ){
        	if( window.store.getState().adminState.pauseGlobalEventExecution ){
        		this.alterEventExecution = false;
        	} else {
        		window.store.dispatch({
        		    type: 'UPDATE_ADMIN_STATE', 
        		    payload: {
        		        pauseGlobalEventExecution: true
        		    }
        		});
        	}
    	}

    	if( options.manuallyBindHotkeys ){
    		// Bind hotkeys if they aren't passed
    		// by proxy. This should only be done for
    		// backbone view mashups, like the account manager or 
    		// commerce windows. 
    		this.internalHotKeySet = true;

    		window.addEventListener("keydown", this.onKeyDown, true );

    	}

		if( !this.state.HotKeyProxy && !options.manuallyBindHotkeys && !this.unregisterEscHotKey){
			this.unregisterEscHotKey = HotKeyManager.registerHotKey(
				'escape',
				{ keyCode: 27 },
				this.cancelModal,
				'alert'
			);
		}

		if( !this.state.HotKeyProxy && !options.manuallyBindHotkeys && !this.unregisterEnterHotKey){
			this.unregisterEnterHotKey = HotKeyManager.registerHotKey(
				'enter',
				{ keyCode: 13 },
				this.confirmModal,
				'alert'
			);
		}

    	window.addEventListener('keydown', this.onTabKeyDown);

        // Homepage unmounts the component immediatly.
        // Account manager relies on this.unmounted to work properly / as expected.
        // this.ignoreUnmount argument allows the original alert to work as expected with the Homepage.
        // Not a factor with the C3 admin where the Alert component is never unmounted.
        if( options.ignoreUnmount ){
        	this.ignoreUnmount = true;
        }

        // Save current scroll style and re-apply on unmount.
        this.originalStyle = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = "hidden";

        // Set state on both for redundancy if spam opening/closing of modal.
        // The modal is transparent
        window.requestAnimationFrame(() => {

        	if( this.unmounted && !this.ignoreUnmount ) return;

	        this.setState({
	            modalOpen: true,
	            animate: false,
	            ...options
	        }, ()=> {
	        	// Request animation frame before animation start...
	        	// Setting animate: true starts the opacity / tranform changes.
	        	window.requestAnimationFrame(() => {

	        		if( this.unmounted && !this.ignoreUnmount ) return;

	        	    this.setState({ 
	        	    	modalOpen: true,
	        	        animate: true 
	        	    }, () => {
	        	    	setTimeout(() => {
	        	    		this.transitioning = false 
	        	    	}, 280)
	        	    });
	        	});
	        })
    	})

        // always clear editor focus when modal opens
        if( options.frontendData ){
        	options.frontendData.contentWindow.getSelection().removeAllRanges();
        }

        window.focus()

    }

    dispatchCloseEvents = () => {
    	const alertClosedEvent = new Event('closeAlertModal');
    	window.dispatchEvent( alertClosedEvent );

    	if( window.__c3_admin__ === true && this.alterEventExecution ){
	    	window.store.dispatch({
	    	    type: 'UPDATE_ADMIN_STATE', 
	    	    payload: {
	    	        pauseGlobalEventExecution: false
	    	    }
	    	}); 
    	}
    }

    unbindInternalHotkeys = () => {
    	if( this.internalHotKeySet ){
    		// Bind hotkeys if they aren't passed
    		// by proxy. This should only be done for
    		// backbone view mashups, like the account manager or 
    		// commerce windows. 
    		this.internalHotKeySet = false;

    		window.removeEventListener("keydown", this.onKeyDown, true );
    	}

		if( this.unregisterEscHotKey ){
			this.unregisterEscHotKey();
			this.unregisterEscHotKey = null;
		}

		if( this.unregisterEnterHotKey ){
			this.unregisterEnterHotKey();
			this.unregisterEnterHotKey = null;
		}

    	window.removeEventListener('keydown', this.onTabKeyDown);

    }

    closeModal = (opts)=> {
    		
    	if( !this.state.modalOpen  ){ return }
	
		const options = _.defaults(opts, this.modalDefaults);

    	this.transitioning = true;

    	this.dispatchCloseEvents();
    	this.unbindInternalHotkeys();
    	
      	// Re-enable scrolling when component unmounts
    	document.body.style.overflow = this.originalStyle;

    	// Request animation frame before transition begins...
    	window.requestAnimationFrame(() => {

    		if( this.unmounted && !this.ignoreUnmount ) return;

	    	this.setState({
	    		modalOpen: true,
	    		animate: false,
	    	}, () => {
	    		// Wait for animation to complete.
	    		// Set state on both for redundancy if spam opening/closing of modal.
	    		setTimeout(() => {

	    			if( this.unmounted && !this.ignoreUnmount ) return;
	    			
	    			this.setState({
	    			    modalOpen: false,
	    			    animate: false,
	    			}, ()=> {

	    				if ( this.state.onClose ){

	    				    this.state.onClose();

	    				    this.setState({
	    				        modalOpen: false,
	    				        animate: false,
	    				        processConfirm: false,
	    				        existingValue: null,
	    				        ...this.modalDefaults
	    				    }, ()=> {
	    						this.transitioning = false;
	    					}); 
	    				    
	    				}

	    			}); 

	    		}, 280)
	    	})
    	})
    }

    validateInput = () => {
    	
    }

    confirmModal = ( e ) => {

    	if( this.state.closeManually ){
    		this.setState({ processConfirm: true })
    		this.state.onConfirm();
    		return
    	}

    	// if( this.transitioning && e?.key === 'Enter' ){ return }
    	if (this.state.type === 'dialog' && this.inputRef.current) {
    		if ( this.state.onConfirm ){
				let confirmValue = this.inputRef.current.value;
				this.setState({ existingValue: confirmValue }, ()=> {
					this.state.onConfirm(confirmValue);
				})
                // this.state.onConfirm(this.inputRef.current.value);                    
            }
    	} else {
			let options = {};
			options['checkBoxConfirmationValue'] = this.state.checkBoxConfirmationValue ?? null;
    		this.state.onConfirm(options)
    	}
       
        this.closeModal();
    }

    onKeyDown = (e) => {

        if ( e?.key === 'Enter' && this.state.type !== 'slide-confirm'){
            this.confirmModal( e );
        }
        if ( e?.key === 'Escape'){
        	e.preventDefault();
        	e.stopImmediatePropagation();
            this.cancelModal( e );
        }
    }

    denyModal = () => {

    	if( this.state.processConfirm ){ return }
    		
    	if ( this.state.onDeny ){
			let options = {};
			options['checkBoxConfirmationValue'] = this.state.checkBoxConfirmationValue ?? null;
		    this.state.onDeny(options);
    	}
		
		this.closeModal();
    }

    cancelModal = ( type ) => {

        if (this.state.preventCancel || this.state.preventClose){
            return;
        }

        if ( this.state.onDeny && type === 'action' ){
            this.state.onDeny();
        }

        if( type?.type === 'keydown' ){
        	type.preventDefault();
        	type.stopImmediatePropagation();
        }

        if (this.state.onCancel) {
        	this.state.onCancel();
        }

        this.closeModal();
    }

    overlayClick = (e) => {

    	if( this.clickStartedInModal ){
    		this.clickStartedInModal = false;
    		return
    	}
        if ( e.target == this.overlayRef.current){
            this.cancelModal();
        }
    }

   	onMouseDown = (e) => {
   		this.clickStartedInModal = true;
   	}

   
   	onTabKeyDown = (e) => {

   		const tabPressed = e.key === 'Tab' || e.keyCode === 9;

   		if(this.state.modalOpen && tabPressed) {
   			e.preventDefault()
   		}

   	}

    componentDidMount() {

    }

    componentWillUnmount(){
		this.unmounted = true;
    }

	render() {

		let confirmButtonText = this.state.confirmButton || 'OK';
		let denyButtonText = this.state.denyButton || 'Cancel'
		const header = this.state.header && <div className="header">{this.state.header}</div>;
		const message = this.state.message && <div className="sub-message">{this.state.message}</div>;
		const htmlHeader = this.state.htmlHeader && <div className="header" dangerouslySetInnerHTML={{__html: this.state.htmlHeader}} />;
		const htmlMessage = this.state.htmlMessage && <div className="sub-message" dangerouslySetInnerHTML={{__html: this.state.htmlMessage}} />;
		const placeholder = this.state.placeholder ? this.state.placeholder : '';
		const existingValue = this.state.existingValue ? this.state.existingValue : '';
		const confirmButton = this.state.confirmButton !== false && <button button-style="rounded-2" className="confirm" onClick={(e)=> this.confirmModal(e, null)}>{confirmButtonText}{this.state.processConfirm ? <ProcessingAnimation className="default-border-radius" /> : ''}</button>;
		const cancelButton = !this.state.preventCancel && <button button-style="rounded-2" className="deny" onClick={this.denyModal}>{denyButtonText}</button>;
		const children = this.state.children && !this.state.wrapperlessChildren ? <div className="form">{this.state.children}</div> : this.state.wrapperlessChildren ? this.state.children : null;

		// Alert Modal
		const AlertUI = () => {
			return(
		        <>     
		        	<div className="message">                       
		                {header}
		                {message}
		            </div>
		            <div className="opt-buttons">
		            	{cancelButton}
						{confirmButton}
					</div> 
		        </>            
			)
		}

		// Notice Modal
		const NoticeUI = () => {
			return(
		        <>     
	                <div className="message">                       
	                    {header}
	                    {message}
	                    {htmlHeader}
	                    {htmlMessage}
	                </div>
	                <div className="input"></div>
	                <div className="buttons">
	    				{confirmButton}
	    			</div> 
		        </>            
			)
		}

		// Confirm Modal
	    const ConfirmUI = () => {
	    	return (
	            <>
	                <div className="message">                       
	                    {header}
						{htmlHeader}
	                    {message}
	                    {htmlMessage}
	                </div>
	                {children}
	                <div className="input"></div>
	                <div className="buttons">
	                	{cancelButton}
	    				{confirmButton}
	    			</div>                           
	            </>
	    	)
	    }

	    // Dialog Modal
	    const DialogUI = () => {
		    return(
			    <>
			        <div className="message">                       
			            {header}
			            {message}
			        </div>
			        <div className="input">
			                {this.state.maxInputLength ? ( 
			        			<input type="text" maxLength={ this.state.maxInputLength } placeholder={placeholder} autoFocus={true} defaultValue={existingValue} ref={this.inputRef}></input>
			        		):( 
			        			<input type="text" placeholder={placeholder} autoFocus={true} defaultValue={existingValue} ref={this.inputRef}></input> 
			        		)}
			        </div>
			        
			        <div className="buttons">
			            <button 
							button-style="rounded-2"
			            	className="deny" 
			            	onClick={this.denyModal}
			            >
			            	Cancel
			            </button>
						<button 
							button-style="rounded-2"
							className="confirm" 
							onClick={(e)=> this.confirmModal(e, null)}
						>
							OK
						</button>
					</div>
			    </>
			)
	    }

	    // Publish Modal
	    const PublishUI = () => {
	    	return (
		    	<>
					<div className="message">                       
					    {header}
					    {message}
					</div>

					{this.state.checkBoxConfirmation ?
						<div className="footer">
							<label 
								onClick={(e)=>{
									e.preventDefault();
									this.setState({
										checkBoxConfirmationValue: !this.state.checkBoxConfirmationValue
									})
								}} 
								className="checkbox" 
								htmlFor="alert_checkbox_confirmation"
							>

								{this.state.checkBoxConfirmation}
								<input 
									readOnly={true}
									name="alert_checkbox_confirmation" 
									id="alert_checkbox_confirmation" 
									type="checkbox" 
									checked={this.state.checkBoxConfirmationValue ? 'checked' : ''}
								/>
							</label>
						</div>
					: null }

					<div className="buttons">
					    {this.state.cancelButton ?
					    	<button
								button-style="rounded-2"
					    		className="deny" 
					    		onClick={this.cancelModal}
					    	>
					    		Cancel
					    	</button>
						: null}
					    <button 
							button-style="rounded-2"
					    	className="deny" 
					    	onClick={this.denyModal}
					    >
					    	{this.state.denyButton}
					    </button>
						<button 
							button-style="rounded-2"
							className="confirm" 
							onClick={(e)=> this.confirmModal(e, null)}
						>
							{this.state.confirmButton}
						</button>
					</div>
		    	</>
	    	)
	    }

	    // Render cycle for modal and its UI
		return (
		    <AlertContext.Provider value={{
		            openModal: this.openModal,
		            closeModal: this.closeModal
		        }}>
		
		        {this.state.modalOpen ? (
		        	<>
		        		{ this.state.HotKeyProxy ? ( 
		        		<>
	    					{this.state.type !== 'slide-confirm' && this.state.HotKeyProxy( "enter", "all", this.confirmModal )}
	    					{this.state.HotKeyProxy( "escape", "all", this.cancelModal )}
		        		</> ) : ( null )}

			           <div
			                className={`alert-window${this.state.animate ? ' active' : ''}${this.state.preventCancel || this.state.preventClose ? ' no-exit' : ''}${this.state.overCart ? ' over-cart' : ''}`}
			                onClick={this.overlayClick}
			                ref={this.overlayRef}
			          	>
			                { this.state.customElement ? this.state.customElement : (

			                    <div 
			                    	onMouseDown={this.onMouseDown}
			                    	className={`alert-modal${' '+this.state.type}${this.state.className ? ' '+this.state.className : ''}`}
			                    >

			                        { this.state.type === 'alert'   && <AlertUI   /> }

			                        { this.state.type === 'notice'  && <NoticeUI  /> }

			                        { this.state.type === 'confirm' && <ConfirmUI /> }

			                        { this.state.type === 'dialog'  && <DialogUI  /> }

			                        { this.state.type === 'publish' && <PublishUI /> }              

			                        { this.state.type === 'custom'  && 
			                        	<>
			                        		<div className="message">                       
			                        		    {header}
			                        		    {message}
			                        		</div>

				                        	{children}
			                        	</>
			                    	}   

        	                        { this.state.type === 'slide-confirm' && 

                    	                <>
                    	                	<div className="message">                       
                    		                    {header}
                    		                    {message}
                    		                    {htmlHeader}
                    		                    {htmlMessage}
                    		                    {children}
                    		                </div>
                    	                    <div className="buttons">
                    	                    	<br/>
                    	                    	<br/>
                    	                    	<button className="deny" onClick={this.denyModal}>Cancel</button>
                    							<SlideConfirm
                    								onConfirm={e=>this.confirmModal(e, null)}
                    								slideMessage={this.state.slideMessage ?? `Slide to delete`}
                    							/>
                    						</div>                
                    	                </>
        	                    	}                            
			                        
			                    </div>

			                )}
			            </div>
		            </>
		        ) : null}

		    	{/* This renders the APP */}
		        {this.props.children}
		   
		    </AlertContext.Provider>
		)
		
	}
}
