import { Component, createRef } from "preact";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import _ from 'lodash';
import { HOMEPAGE_ORIGIN } from "@cargo/common/environment";
import * as helpers from "@cargo/common/helpers";
import { Login } from "@cargo/common/login";
import { actions } from "../actions";
import withStore from '../withStore';
import Routes from "../routes";
import ScrollContextProvider from "./page/scroll-element";
import GlobalEventsHandler from "./global-events-handler";
import windowInfo from "./window-info";
import Password from './page/password';
import FrontendAlertProxy from './frontend-alert-proxy';
import NotificationPage from './notification-page';
import loadFonts from '@cargo/font-loader';
import { CustomElementHost } from './page/register'
import "./page/media-item";
import "./page/gallery/layouts/grid";
import "./page/gallery/layouts/slideshow";
import "./page/gallery/layouts/columnized";
import "./page/gallery/layouts/freeform";
import "./page/gallery/layouts/justify";
import "./page/columns";
import "./page/audio-player";
import "./page/map";
import "./page/digital-clock";
import "./page/shop-product";
import "./page/marquee";
import "./page/text-icon-element"
import EditorOverlayController from './overlay/editor-overlay-controller';
import { getMobileOffsetsString } from "../helpers";
import Filter from './colorfilter';

let frontLoadComplete = false;
let firstFontLoadComplete = false;
let backdropLoadComplete = true;

const dispatchFirstRenderEvent = (forceInitialRenderEvent = false) => {

	if(forceInitialRenderEvent || (frontLoadComplete && firstFontLoadComplete && backdropLoadComplete)) {
		setTimeout(() => {
			// backdrop loads might initiate at a later point, check again 
			// here in case backdrops started loading in the mean time
			if(backdropLoadComplete) {
				window.dispatchEvent(new CustomEvent('initial-content-visible', {
					detail: {
						screenshots: {
							hiddenIds: [ 'dl-duplicate-site' ],
						}
					}
				}));
			}
		}, 1500);
	}

}

const frontload = async props => { 

	const frontloadPromises = [];

	if(props.hasSitePackage !== true) {

		frontloadPromises.push( 
			props.fetchSitePackage()
		);

	}

	// load fonts when server rendering this site
	if(helpers.isServer && props.fonts && _.isArray(props.fonts)) {

		// do not load Google Fonts in a server thread. They need to be requested by the
		// client so their api can sniff browser support.
		const fontsToLoad = [...props.fonts].filter(font => font.provider !== 'google');

		const fontLoadPromise = loadFonts({
			"context": "server",
			"families": fontsToLoad
		});

		fontLoadPromise.then(results => {

			props.updateFrontendState({
				fontsLoaded: _.uniq(props.fontsLoaded.concat(fontsToLoad.map(font => font.family))),
				serverRenderedFontCSS: results.filter(result => result ?? false)
			});

		})

		frontloadPromises.push(fontLoadPromise);

	}

	await Promise.allSettled(frontloadPromises);

	frontLoadComplete = true;

	dispatchFirstRenderEvent();
	
}

class App extends Component {

	constructor(props) {

		super(props);

		this.state = {
			checkedExistingAuth: helpers.isServer ? true : false,
			checkedCookieAuth: helpers.isServer ? true : false,
			mobileOffsetsString: null,
			inheritableFontSizesString: null,
			linkStylesString: null,
			fontFeatureSettingsStylesString: null,
		}

		if(!helpers.isServer) {

			import('./quick-view').then(({default: QuickViewComponent}) => {
				this.setState({
					QuickView: QuickViewComponent
				})
			})

			if(props.inAdminFrame === true) {
				const head  = document.getElementsByTagName('head')[0];
				const link  = document.createElement('link');
				link.rel  = 'stylesheet';
				link.type = 'text/css';
				link.href = PUBLIC_URL + '/css/front-end/frontend-interface.css';
				link.id = 'frontend-interface'
				head.appendChild(link);
			}

			// set mobile class if needed
			this.setMobileClass(this.props.isMobile);
			this.setOSClass();

			let backdropLoadStarts = 0;
			let backdropLoadCompletes = 0;

			window.addEventListener('backdrop-load-start', () => {

				backdropLoadComplete = false;
				backdropLoadStarts++;

			})

			window.addEventListener('backdrop-load-complete', () => {

				backdropLoadCompletes++;

				if(backdropLoadCompletes === backdropLoadStarts) {
					backdropLoadComplete = true;
					dispatchFirstRenderEvent()
				}

			});

		}

		this.globalStyleRef = createRef();
		this.customHTMLRef = createRef();
		// this.cargoSiteLogoRef = createRef();
		// this.cargoSiteLogoSVGRef = createRef();
		// this.cargoSiteLogoPathRef = createRef();

		this.scrollingElement = {current: helpers.isServer ? null : window};

		this.setWindowTitle();
	}

	setWindowTitle = () => {

		if(helpers.isServer) {
			return;
		}

		const state = this.props.store.getState();
		let activePageOrSet = undefined;
		let title = "";

		if (
			this.props.activePID
			&& this.props.activePID !== state.site.homepage_id
			&& this.props.activePID !== state.site.mobile_homepage_id
			&& this.props.activePID !== 'root'
		) {
			if (state.pages.byId[this.props.activePID]) {
				activePageOrSet = state.pages.byId[this.props.activePID];
			} else if (state.sets.byId[this.props.activePID]) {
				activePageOrSet = state.sets.byId[this.props.activePID];
			}
		}

		if (this.props.website_title) {
			const pageTitle = (activePageOrSet?.title) ? `${activePageOrSet.title} — ` : '';
			title = `${pageTitle}${this.props.website_title}`;
		} 

		if(title !== document.title) {
			document.title = title;
		}

	}

	onFirstSiteLoad() {

		// test if in the editor
		let editing = false;

		try {
			editing = parent.__c3_admin__ === true;
		} catch(e) {};

		// dispatch site load event
		window.dispatchEvent(new CustomEvent("cargo-site-load", {
			detail: {
				editing
			}
		}));

	}

	render() {

		if(this.props.hasSitePackage !== true) {
			return null;
		}

		// redirect before we hit auth so we don't authenticate
		// on the current domain, then redirect, then have to auth 
		// on the redirected domain again.
		if(
			!helpers.isServer
			// we're not in the admin, which handles its own redirect.
			&& !helpers.isAdminEdit
			// This site has an active domain
			&& this.props.domain_active === true
			// but we are currently not loading the site through that domain
			&& this.props.domain !== window.location.host 
			// not in site preview on u.cargo
			&& !helpers.isSitePreview
		) {

			window.location.hostname = this.props.domain;

			return null;
		}

		if(
			this.props.access_level === 'password'
			|| this.props.access_level === 'private'
		) {

			let jsx = null;

			if(this.props.hasAuth && this.state.checkedExistingAuth === false) {

				// we have auth but the page is private. Attempt to fetch the site package
				// with the pre-existing auth data first
				if(!this.fetchingSitePackage) {
					
					this.fetchingSitePackage = true;

					this.props.fetchSitePackage()
						.finally(() => {
							delete this.fetchingSitePackage;

							this.setState({
								checkedExistingAuth: true
							});

						})
				}

			} else if(this.state.checkedCookieAuth === false) {

				// else attempt to automatically retrieve token
				if(!this.checkingAuth) {

					this.checkingAuth = true;

					this.props.getAuthToken({
						// add a relatively short timeout
						// because we don't want to get stuck in this mode
						// for too long
						timeout: 1500,
						site_url: this.props.site_url,
						site_id: this.props.site_id
					}).then(() => {

						// got a token, attempt to fetch the package
						// and then mark cookie check as completed.
						this.props.fetchSitePackage()
							.finally(() => {
								delete this.checkingAuth;
								this.setState({
									checkedCookieAuth: true
								});
							})

					}).catch(e => {

						this.setState({
							checkedCookieAuth: true
						});

					}).finally(() => {
						delete this.checkingAuth;
					})

				}

			} else {

				if(this.props.access_level === 'password') {

					jsx =  <Password target="site" onSucces={() => {
						// re-fetch package with new data
						this.props.fetchSitePackage();
					}}/>

				} else if(this.props.access_level === 'private') {

					jsx = <NotificationPage message="This site is private."/>;

				}

				// let the screenshotter know we're done.
				this.onFontLoad(true);

			}

			return <>
				<GlobalEventsHandler>
					{ jsx }
				</GlobalEventsHandler>
			</>

		}

		// only set custom HTML using dangerouslySetInnerHTML for the initial render. After
		// that we use the method in componentDidUpdate
		const cachedCustomHTML = this.props.customHTML;
		// const canDuplicate = this.props.canDuplicate && !helpers.isServer;

		return (
			<>
				{ this.props.enableColorFilter ? <Filter /> : null }
				<customhtml ref={this.customHTMLRef} dangerouslySetInnerHTML={ {__html: cachedCustomHTML} }></customhtml>
				
				<FrontendAlertProxy />
				<GlobalEventsHandler />

				<style ref={this.globalStyleRef} dangerouslySetInnerHTML={{__html: this.props.stylesheet}}></style>
				<style id="mobile-offset-styles" dangerouslySetInnerHTML={{__html: this.state.mobileOffsetsString}}></style>
				<style id="text-style-font-sizes" dangerouslySetInnerHTML={{__html: this.state.inheritableFontSizesString}}></style>
				<style id="link-styles" dangerouslySetInnerHTML={{__html: this.state.linkStylesString}}></style>
				<style id="font-feature-settings-styles" dangerouslySetInnerHTML={{__html: this.state.fontFeatureSettingsStylesString}}></style>

				<ScrollContextProvider scrollingElement={this.scrollingElement}>
					<Routes />
					{this.state.QuickView && <this.state.QuickView />}
					{this.props.inAdminFrame && <EditorOverlayController />}
					{this.state.loginBeforeDuplicatingSite && <Login 
						loginToUdotCargoOnly={true}
						/* only allow signups on desktop */
						canCreateNewAccount={!this.props.isMobile} 
						animateIn={true}
						canClickout={true}
						templateID={this.props.site_id}
						onClickout={() => {
							this.setState({
								loginBeforeDuplicatingSite: false
							})
						}}
						onLoginSuccess={data => {
							window.location.href = `${HOMEPAGE_ORIGIN}?duplicate=${this.props.site_id}`;
						}
					}/>}
					<CustomElementHost portalHost={helpers.isServer ? null : document.body}></CustomElementHost>
				</ScrollContextProvider>
			</>
		);
	}

	componentDidMount() {

		// load any remaining fonts
		this.loadNewFonts();

		this.inPreviewMode = this.props.inAdminFrame && this.props.adminMode === false;
		this.showHideAdminInterface();

		windowInfo.on('mobile-change', this.onMobileChange);

		// set initial
		this.onMobileChange(windowInfo.data.mobile.active);

		this.updateCartCounter();

		if(this.props.hasSitePackage) {
			// site package is instantly available indicating a server render
			// wait two frames for things to settle after hydration
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					this.onFirstSiteLoad();
				});
			})
		}

	}

	static getDerivedStateFromError(error) {

		return {
			hasError: true
		};

	}

	componentDidCatch(error, errorInfo) {
		
		// log this to some error logging service
		console.error(error);

	}

	onMobileChange = (isMobile) => {
		
		this.props.updateFrontendState({
			isMobile
		});

	}

	onFontLoad = (forceInitialRenderEvent = false) => {

		if(helpers.isServer || firstFontLoadComplete) {
			return;
		}

		firstFontLoadComplete = true;
		document.documentElement.classList.remove('wf-initial-load');
		dispatchFirstRenderEvent(forceInitialRenderEvent);
	}

	componentWillUnmount(){
		windowInfo.off('mobile-change', this.onMobileChange)
	}

	showHideAdminInterface = () => {
		if (this.inPreviewMode) {
			document.querySelector('#frontend-interface')?.setAttribute('disabled', '');
		} else {
			document.querySelector('#frontend-interface')?.removeAttribute('disabled');
		}
	}

	setOSClass = () => {
		if (!helpers.isMac() && !helpers.isIOS()) {
			document.body.classList.add('f-f');
		}
	}

	setMobileClass = (state) => {
		if (state) {
			document.body.classList.add('mobile')
			document.documentElement.classList.add('mobile')
		} else {
			document.body.classList.remove('mobile')
			document.documentElement.classList.remove('mobile')
		}
	}

	setEditingClass = (state) => {
		if (state) {
			document.body.classList.add('editing')
		} else {
			document.body.classList.remove('editing')
		}
	}

	loadNewFonts = () => {

		if(!this.props.fonts) {
			return;
		}

		const newFonts = this.props.fonts.filter(font => !this.props.fontsLoaded.includes(font.family));

		if(newFonts && newFonts.length > 0) {

			this.props.updateFrontendState({
				fontsLoaded: _.uniq(this.props.fontsLoaded.concat(newFonts.map(font => font.family)))
			});

			loadFonts({
				families: newFonts
			}).then(() => {
				if(!firstFontLoadComplete) {
					
					document.fonts.ready.then(() => {
						this.onFontLoad();
					});

				}
			});

		} else {

			// Nothing to load, mark font loads as completed.
			document.fonts.ready.then(() => {
				this.onFontLoad();
			});

		}

	}

	componentDidUpdate(prevProps, prevState) {

		if(helpers.isServer) {
			// nothing below should run on the server
			return;
		}

		this.setWindowTitle();

		this.inPreviewMode = this.props.inAdminFrame && this.props.adminMode === false;

		if(typeof this.props.customHTML === "string" && this.props.customHTML !== prevProps.customHTML) {

			if(this.customHTMLRef.current) {

				// scripts won't execute when simply setting innerHTML.
				const newHTML = document.createRange().createContextualFragment(this.props.customHTML)

				this.customHTMLRef.current.innerHTML = '';
				this.customHTMLRef.current.appendChild(newHTML);
			}

		}

		// if(this.props.showCargoLogo) {

		// 	if (this.cargoSiteLogoRef?.current) {
		// 		this.cargoSiteLogoRef.current.setAttribute('style', 'all: unset !important; display: inline-block !important; position: fixed !important; mix-blend-mode: difference !important; z-index: 9999 !important; opacity: 0.2 !important; cursor: pointer !important; width: 84px !important; height: 19px !important; line-height: 1.65 !important; margin: auto !important; margin-left: 0 !important; margin-right: 0 !important; padding: 0 !important; left: unset !important; right: 1.4rem !important; top: calc(100vh - calc(1.4rem + 19px)) !important; bottom: auto !important;');
		// 	}

		// 	if (this.cargoSiteLogoSVGRef?.current) {
		// 		this.cargoSiteLogoSVGRef.current.setAttribute('style', 'all: unset !important; display: inline-block !important; position: absolute !important; z-index: 1 !important; opacity: 1 !important; width: 84px !important; height: 19px !important; margin: auto !important; margin-left: 0 !important; margin-right: 0 !important; padding: 0 !important; left: unset !important; inset: 0 !important;');
		// 	}

		// 	if (this.cargoSiteLogoPathRef?.current) {
		// 		this.cargoSiteLogoPathRef.current.setAttribute('style', 'display: inline-block !important; background-color: transparent !important; z-index: 1 !important; opacity: 1 !important; width: 100% !important; fill: rgb(255, 255, 255) !important; height: auto !important; margin: auto !important; padding: auto !important; visibility: visible !important; inset: auto !important; transform: none !important; position: relative !important; animation: none !important; transition: none !important; border: none !important; outline: none !important; overflow: visible !important; stroke: none !important;');
		// 	}

		// }

		if( this.props.stylesheet !== prevProps.stylesheet ) {
			this.setMobileOffsets();
			this.setInheritableFontSizes();
			this.setLinkStyles();
			this.setFontFeatureSettingsStyles();
		}

		if( this.props.fonts !== prevProps.fonts && _.isArray(this.props.fonts) ) {
			this.loadNewFonts();
		}

		if(this.props.inAdminFrame === true) {

			// add a class while in the admin (but not in preview)
			this.setEditingClass(this.props.adminMode);

			if(this.props.adminInitialized !== prevProps.adminInitialized) {
				// when the admin is initialized we have the CSS from the CRDT. Load fonts from it
				this.setMobileOffsets();
				this.setInheritableFontSizes();
				this.setLinkStyles();
				this.setFontFeatureSettingsStyles();
			}

		} else {

			// remove 'editing' class out of the admin
			this.setEditingClass(false);

		}

		if(prevProps.isMobile !== this.props.isMobile) {
			this.setMobileClass(this.props.isMobile);
			this.setMobileOffsets();
			this.setInheritableFontSizes();
			this.setLinkStyles();
			this.setFontFeatureSettingsStyles();
		}

		if(this.props.itemsInCart !== prevProps.itemsInCart) {
			this.updateCartCounter();
		}

		this.showHideAdminInterface();

		if(
			this.props.hasSitePackage
			&& !prevProps.hasSitePackage
		) {
			// wait one render cycle for the site package to be applied to the DOM
			requestAnimationFrame(() => {
				this.onFirstSiteLoad();
			})
		}
	}

	updateCartCounter = () => {

		document.documentElement.style.setProperty("--cart-item-count", this.props.itemsInCart || "");
		// control visibility of the cart item counter
		document.documentElement.style.setProperty("--cart-item-count-display", this.props.itemsInCart ? 'inline' : "");

	}

	setFontFeatureSettingsStyles = () => {
		if(helpers.isServer) {
			return;
		}

		if (!this.props.stylesheet) {
			return;
		}

		const isolatedStyle = this.getIsolatedStyleSheet();
		isolatedStyle.innerHTML = this.props.stylesheet;
		let stylesheet = isolatedStyle.sheet;

		if(!stylesheet) {return null;}

		// First, check if the stylesheet bodycopy has a font-feature-settings property
		// If it does, then continue otherwise return
		let bodycopyHasFontFeatureSettings = false;
		for ( const rule of stylesheet.cssRules ) {
			if (rule.selectorText === 'bodycopy' && rule.style.getPropertyValue('font-feature-settings')) {
				bodycopyHasFontFeatureSettings = true;
				break;
			} else {
				continue;
			}
		}

		if (!bodycopyHasFontFeatureSettings) {
			return;
		}

		let fontFeatureSettingsStylesString = '';
		// first loop finding all text style classes
		const textStyleSelectors = [];
		const hTags = [];

		_.each(stylesheet.rules, rule => {

			if(!rule || !rule.selectorText) {
				return;
			}

			// If the rule's selector text includes a space, skip it
			if (rule.selectorText.includes(' ')) {
				return;
			}

			if(
				( rule.style.getPropertyValue('--text-style')
				|| rule.selectorText === '.caption' ) 
				&& !rule.style.getPropertyValue('font-feature-settings')
			) {
				textStyleSelectors.push(rule.selectorText);
			}

			if (rule.selectorText.match(/h[1-6]/gi) && !rule.style.getPropertyValue('font-feature-settings')) {
				hTags.push(rule.selectorText);
			}

		});

		for ( const selector of textStyleSelectors ) {
			fontFeatureSettingsStylesString += selector + ' { font-feature-settings: normal; } ';
		}

		for ( const selector of hTags ) {
			fontFeatureSettingsStylesString += selector + ' { font-feature-settings: normal; } ';
		}

		this.setState({fontFeatureSettingsStylesString: fontFeatureSettingsStylesString});

	}

	setLinkStyles = () => {
		if(helpers.isServer) {
			return;
		}

		if (!this.props.stylesheet) {
			return;
		}

		const isolatedStyle = this.getIsolatedStyleSheet();
		isolatedStyle.innerHTML = this.props.stylesheet;
		let stylesheet = isolatedStyle.sheet;

		if(!stylesheet) {return null;}

		let linkStylesString = '';

		// first loop finding all text style classes
		const textStyleSelectors = [];
		const hTags = [];

		_.each(stylesheet.rules, rule => {

			if(!rule || !rule.selectorText) {
				return;
			}

			if(
				rule.style.getPropertyValue('--text-style')
				|| rule.selectorText === '.caption'
			) {
				textStyleSelectors.push(rule.selectorText);
			}

			if (rule.selectorText.match(/h[1-6]/gi)) {
				hTags.push(rule.selectorText);
			}

		});

		// loop again and handle text style selectors with link styles in them
		_.each(stylesheet.rules, rule => {

			if(!rule || !rule.selectorText) {
				return;
			}

			textStyleSelectors.forEach(selector => {

				if(rule.selectorText.startsWith(selector) && rule.selectorText.includes(' a')) {
					const flippedSelector = rule.selectorText.replace(/^(.*)\s(\a(?:[:.]\S*)?)$/i, (match, group1, group2) => {
						return group2 + group1;
					})
					
					// copy link styles from '.caption a' (for example) to 'a.caption' (flippedSelector)
					if(rule.selectorText !== flippedSelector) {
						linkStylesString += flippedSelector + ' { ' + (rule.style.cssText || '') + ' } ';
					}

					// add default 'unset' of text-decoration-color to the original selector AND the flipped selector
					// ignore pseudo selectors
					let linkPseudoSelector = rule.selectorText.includes(' a::') || rule.selectorText.includes(' a:') ? true : false;
					let declaredTextDecorationColor = rule.style.getPropertyValue('text-decoration-color') === '' ? ` text-decoration-color: unset;` : '';
					if (declaredTextDecorationColor !== '' && !linkPseudoSelector) {
						// flipped selector
						linkStylesString += flippedSelector + ' { ' + (rule.style.cssText || '') + declaredTextDecorationColor + ' } ';
						// original selector
						linkStylesString += rule.selectorText + ' { ' + declaredTextDecorationColor + ' } ';
					}

				}

			});

			hTags.forEach(selector => {

				if(rule.selectorText.startsWith(selector) && rule.selectorText.includes(' a')) {

					let linkPseudoSelector = rule.selectorText.includes(' a::') || rule.selectorText.includes(' a:') ? true : false;
					let declaredTextDecorationColor = rule.style.getPropertyValue('text-decoration-color') === '' ? ` text-decoration-color: unset;` : '';

					if (declaredTextDecorationColor !== '' && !linkPseudoSelector) {
						linkStylesString += rule.selectorText + ' { ' + declaredTextDecorationColor + ' } ';
					}
				}

			});

		});

		this.setState({linkStylesString: linkStylesString});

	}

	setInheritableFontSizes = () => {
		if(helpers.isServer) {
			return;
		}

		if (!this.props.stylesheet) {
			return;
		}
		const isolatedStyle = this.getIsolatedStyleSheet();
		isolatedStyle.innerHTML = this.props.stylesheet;
		let stylesheet = isolatedStyle.sheet;

		if(!stylesheet) {return null;}

		let inheritableFontSizesString = '';

		_.each(stylesheet.rules, rule => {
			if (	
				rule.style?.fontSize 
				&& rule.style?.fontSize !== ''
			) {
				// calc the font size on the selector that has a declared `font-size`
				let fontSize = rule.style.fontSize;
				let starSelector = rule.selectorText === 'bodycopy' ? ', '+rule.selectorText+' *' : '';
				inheritableFontSizesString += rule.selectorText+' {--font-size: '+fontSize+';}\n'
				inheritableFontSizesString += rule.selectorText+starSelector+' {font-size: calc(var(--font-scale) * var(--font-size));}\n'
			}

		});

		this.setState({inheritableFontSizesString: inheritableFontSizesString});
		
	}

	setMobileOffsets = () => {

		if(helpers.isServer) {
			return;
		}

		if (!this.props.isMobile) {
			this.setState({mobileOffsetsString: null})
			return;
		}

		if (!this.props.stylesheet) {
			return;
		}

		const isolatedStyle = this.getIsolatedStyleSheet();
		isolatedStyle.innerHTML = this.props.stylesheet;

		const string = getMobileOffsetsString(isolatedStyle.sheet, [
			{
				properties: [
					'padding-top',
					'padding-right',
					'padding-bottom',
					'padding-left',
				],
				denyList: [
					'ul',
					'ul.lineated',
					'ol',
					'li',
					'h1',
					'h2',
					'h3',
					'h4',
					'h5',
					'h6',
					'sub',
					'sup',
					'blockquote',
					'small-caps',
					'match:audio-player',
					'match:cargo-map',
					'match:shop-product',
					'.quick-view',
					'.quick-view .caption',
					'.quick-view .caption-background',
					'.mobile .quick-view',
					'.mobile .quick-view .caption'
				]
			}, {
				properties: [
					'margin-top',
					'margin-right',
					'margin-bottom',
					'margin-left'
				],
				allowList: [
					'.content',
					'.mobile .content'
				]
			}
		])

		this.setState({mobileOffsetsString: string});
	}

	getIsolatedStyleSheet = _.once((css) => {
		const doc = document.implementation.createDocument('http://www.w3.org/1999/xhtml', 'body', null);
		const style = document.createElement('style');
		style.innerHTML = css;
		doc.children[0].appendChild(style);
		return style;
	})

}

function mapReduxStateToProps(state) {

	const canDuplicate = helpers.isServer ? state.site?.is_template : state.site?.is_template && parent === window;

	return {
		hasSitePackage: state.frontendState.hasSitePackage,
		adminInitialized: state.adminState?.initialized || false,
		inAdminFrame: state.frontendState.inAdminFrame,
		isMobile: state.frontendState.isMobile,
		adminMode: state.frontendState.adminMode,
		showCargoLogo: !canDuplicate && state.site?.show_cargo_logo,
		customHTML: state.site?.custom_html,
		access_level: state.site?.access_level,
		website_title: state.site?.website_title,
		domain_active: state.site?.domain_active,
		domain: state.site?.domain,
		site_url: state.site?.site_url,
		site_id: state.site?.id,
		fonts: state.site?.fonts,
		fontsLoaded: state.frontendState.fontsLoaded,
		// return an empty, non-falsy stylesheet if page wasn't found so we do
		// not load any user styles
		stylesheet: state.frontendState.pageNotFound ? ' ' : state.css?.stylesheet,
		hasAuth: state.auth.authenticated,
		canDuplicate: canDuplicate,
		activePID: state.frontendState.activePID,
		itemsInCart: _.values(state.commerce.cart).reduce((acc, item) => acc += (item?.quantity || 0), 0),
		enableColorFilter: !!state.siteDesign.site?.enableColorFilter
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({ 
		fetchSitePackage: actions.fetchSitePackage,
		updateFrontendState: actions.updateFrontendState,
		getAuthToken: actions.getAuthToken
	}, dispatch);

}

export default withStore(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(
	frontloadConnect(frontload, {
		onMount: true,
		onUpdate: false
	})(
		App
	)
));