import { Component , cloneElement, toChildArray, render, createRef} from "preact";
import {createPortal} from "preact/compat";

import { diffProps } from 'node_modules!preact/src/diff/props.js'
import ResizeCard from "../resize-card";
import { ConnectedGrid as Grid } from './grid';

import { connect } from 'react-redux';

import * as helpers from "@cargo/common/helpers";

import FreeformEditor from '../../../overlay/freeform-editor';
import withThumbnailContent from "../thumbnail-index-generator";

import { withPageInfo } from "../../page-info-context";
import windowInfo from "../../../window-info"


import _ from 'lodash';
import register from "../../register";

class Freeform extends Component {

	constructor(props){
		super(props);
		this.state = {
			elWidth: 1000,
			sizeIncrement: 0,

		};

		this.holderRef = createRef();

	}

	render(props, state){
		const {
			adminMode,
			baseNode,
			pageInfo,
			'mobile-grid': mobileGrid,
			'mobile-gutter': mobileGutter,
			'mobile-columns': mobileColumns,
			'mobile-horizontal-align': mobileHorizontalAlign,
			'mobile-vertical-align': mobileVerticalAlign,
			'hover-pop': hoverPop,
			'magnetic-snap': magneticSnap,
			'magnetic-snap-margin': magneticSnapMargin,
		} = props;

		if ( mobileGrid && props.isMobile ) {
			this.renderedMobileGrid = true;
			return <Grid
				{...this.props}
				columns={mobileColumns}
				gutter={mobileGutter}
				verticalAlign={mobileVerticalAlign}
				horizontalAlign={mobileHorizontalAlign}
				baseNode={baseNode}
				adminMode={adminMode}
				pageInfo={pageInfo}
			/>
		}

		const {
			elWidth,
		} = state;

		let elHeight = 0;
		let lastHeightBeforeUndefinedValues = 0;
		let noDataIncrement = 0;

		const mediaItemElements = Array.from(baseNode.children).filter(node=>node.tagName == 'MEDIA-ITEM');


		const shadowStyleMap = [];

		const attributeMap = [];


		let lastIndexWithUsableAttributes = -1;
		mediaItemElements.forEach((el, index)=>{

			let missingData = false;

			let readAttributes = {
				'freeform-scale': parseFloat(el.getAttribute('freeform-scale')),
				'freeform-x': parseFloat(el.getAttribute('freeform-x')),
				'freeform-y': parseFloat(el.getAttribute('freeform-y')),
				'freeform-z': parseFloat(el.getAttribute('freeform-z')),
			}

			const writeAttributes = {
				slot: 'slot-'+index,
				itemResize: this.onItemResize
			}

			if(
				isNaN(readAttributes['freeform-scale']) ||
				isNaN(readAttributes['freeform-x']) ||
				isNaN(readAttributes['freeform-y']) ||
				isNaN(readAttributes['freeform-z']) 
		
			) {
				missingData = true;
				readAttributes['freeform-scale']= Number.isNaN(parseFloat(el.getAttribute('freeform-scale'))) ?  20 : parseFloat(el.getAttribute('freeform-scale'));
				readAttributes['freeform-x']= Number.isNaN(parseFloat(el.getAttribute('freeform-x'))) ?  noDataIncrement*1.5 : parseFloat(el.getAttribute('freeform-x'));
				readAttributes['freeform-x'] = readAttributes['freeform-x']%80;
				readAttributes['freeform-y']= Number.isNaN(parseFloat(el.getAttribute('freeform-y'))) ?  noDataIncrement*1.5 : parseFloat(el.getAttribute('freeform-y'));
				// readAttributes['freeform-z']= Number.isNaN(parseFloat(el.getAttribute('freeform-z'))) ?  0 : parseFloat(el.getAttribute('freeform-z'));
				readAttributes['freeform-z'] = 9999;
				noDataIncrement++
			}


			let xPx = parseFloat(readAttributes['freeform-x'])*.01 * elWidth;
			let yPx = parseFloat(readAttributes['freeform-y'])*.01 * elWidth;
			let z = parseInt(readAttributes['freeform-z']);
			let widthPx = parseFloat(readAttributes['freeform-scale'])*.01 * elWidth;
			
			let height = widthPx;
			const size = el._size || {
				width: 0,
				height: 0,
				mediaSize: {
					width: 0,
					height: 0,
					padSize: 0,
				},
				mediaItemSize: {
					width: 0,
					height: 0,
					padSize: 0
				}
			}

			height = size.mediaItemSize.height + size.mediaItemSize.padSize;
			elHeight = Math.max(yPx+height, elHeight)

			if(!missingData){
				lastIndexWithUsableAttributes = index;
				lastHeightBeforeUndefinedValues = elHeight;
			} 


			readAttributes.widthPx = widthPx;
			readAttributes.xPx = xPx;
			readAttributes.yPx = yPx;
			readAttributes.z = z;
			readAttributes.writeAttributes = writeAttributes;

			attributeMap.push(readAttributes);

		})

		const extraHeight = 100*(lastHeightBeforeUndefinedValues/elWidth);
		let increment = 0;
		for(var i = lastIndexWithUsableAttributes+1; i< attributeMap.length; i++){
			const el = mediaItemElements[i];
			const size = el._size || {
				width: 0,
				height: 0,
				mediaSize: {
					width: 0,
					height: 0,
					padSize: 0,
				},
				mediaItemSize: {
					width: 0,
					height: 0,
					padSize: 0
				}
			}

			let height = size.mediaItemSize.height + size.mediaItemSize.padSize;

			let y = increment*1.5 + extraHeight

			let yPx = y*.01 * elWidth;


			attributeMap[i]['freeform-y'] = y;

			// attributeMap[i].writeAttributes['freeform-y'] = y;
			// attributeMap[i].writeAttributes['freeform-x'] = attributeMap[i]['freeform-x'];
			// attributeMap[i].writeAttributes['freeform-scale'] = attributeMap[i]['freeform-scale'];
			// attributeMap[i].writeAttributes['freeform-z'] = attributeMap[i]['freeform-z'];

			attributeMap[i].yPx = yPx;			

			elHeight = Math.max(attributeMap[i].yPx+height, elHeight)
			increment++;
			
		}

		attributeMap.forEach((readAttributes, index)=>{
			const el = mediaItemElements[index]
			const {
				widthPx,
				yPx, 
				xPx,
				z,
				writeAttributes
			} = readAttributes;

			const props = el._props || {}

			// itemResize needs to get forcibly removed from old props
			// if moving from grid<->freeform
			// so we check if we last rendered mobile
			if( this.renderedMobileGrid ){
				props.itemResize = null;
			}			

			// diffProps(dom, newProps, oldProps, isSvg, hydrate) {
			diffProps(el, {...props, ...writeAttributes}, props, false, false);

			shadowStyleMap.push({
				'width': widthPx +'px',
				'--base-translate': `translate(${Math.floor(xPx)}px, ${Math.floor(yPx)}px)`,
				'--z-index': z+1,
			})			
		});

		this.renderedMobileGrid = false;

		return <>
			{this.props.children}
			{createPortal(<>
				<style>{`
					* {
						box-sizing: border-box;
					}
									
					:host {
						width: var(--resize-parent-width, 100%);
						max-width: 100%;						
						height: ${elHeight}px;
						display: block;
						position: relative;
						contain: layout;
					}
					
					::slotted(media-item) {
						max-width: none;
						position: absolute;
						top: 0;
						left: 0;
						display: flex;
						flex-shrink: 0;
						flex-grow: 0;
					}

					::slotted(media-item)::part(sizing-frame){
						pointer-events:auto;						
					}

					${shadowStyleMap.map((mapItem, index)=>{
						return `::slotted(media-item[slot="slot-${index}"]){
							width: ${mapItem.width};
							--base-translate: ${mapItem['--base-translate']};
							--z-index: ${mapItem['--z-index']};
						} `
					}).join('')}						
					
					${hoverPop ? `::slotted(media-item:hover) {
						z-index: 99999;	
					}`: ''}
					
					#freeform-holder {
						position: absolute;
						inset: 0;
					}
				`}</style>
				<>
					<div id="freeform-holder" ref={this.holderRef}>
						{mediaItemElements.map((el, i)=><slot name={`slot-${i}`}/>)}
					</div>
					<ResizeCard
						values={{
							elWidth: '100%',
						}}
						onResize={this.onResize}
					/>
				</>
				{adminMode && pageInfo.isEditing && !helpers.isServer && <FreeformEditor
					{...props}
					gallerySpecificAttributes={['freeform-x', 'freeform-y', 'freeform-z', 'freeform-scale']}	
					galleryInstance={baseNode}
					galleryComponent={this}
					mediaItemOptions={layoutData.mediaItemOptions}
					disabledMediaItemOptions={layoutData.disabledMediaItemOptions}
					mediaItems={mediaItemElements}
					elWidth={elWidth}
					elHeight={elHeight}
					hoverPop={hoverPop}
					magneticSnap={magneticSnap}
					magneticSnapMargin={magneticSnapMargin}
					attributeMap={attributeMap}
					/>
				}
		
		</>, this.props.baseNode.shadowRoot)}
		</>
	}

	componentDidUpdate(prevProps, prevState){
		if( this.props.isMobile !== prevProps.isMobile ){
			this.onItemResize();
		}
	}

	onResize = (key, size) =>{
		this.setState((prevState)=>{

			const newState = {...prevState};
			newState[key] = size;
			return newState
		})
	}	

	onItemResize = (dimensions, element)=>{
		this.setState(prevState=>{
			return {
				sizeIncrement: prevState.sizeIncrement+1
			}
		})

	}	
}



const ConnectedFreeform = withPageInfo(withThumbnailContent(connect(
    (state, ownProps) => {
        return {
            adminMode: state.frontendState.adminMode,
            isMobile: state.frontendState.isMobile
        };
    }
)(Freeform)));

register(ConnectedFreeform, 'gallery-freeform', [
	'thumbnail-index',
	'links-filter-index',
	'show-tags',
	'show-title',

	'thumbnail-index-metadata',

	'magnetic-snap',
	'magnetic-snap-margin',

	'hover-pop',
	'mobile-grid',
	'mobile-gutter',
	'mobile-columns',
	'mobile-horizontal-align',
	'mobile-vertical-align',
], {
	renderIntoShadow: true
}) 



const layoutData = {
	name: 'freeform',
	displayName: 'Freeform',
	tagName: 'GALLERY-FREEFORM',
	disabledMediaItemOptions: ['limit-by', 'scale'],
	nonAttributeValues: ['horizontalShift', 'fitToWidth', 'zoom', 'shift-x', 'shift-y'],

	mediaItemOptions: [
		{
			type: 'group',
			className: 'grid-columns-even',
			children: [
				{
					labelName: "X",
					name: "shift-x",
					defaultValue: 0,
					type: "scrubber",
					value: 0,
					step: 0.05,
					numberOnlyMode: true,
					onScrubEnd:function(){

						this.state.galleryParent?._freeformEditor?.commitTransform();
					},		
				},
				{
					labelName: "Y",
					name: "shift-y",
					defaultValue: 0,					
					type: "scrubber",
					value: 0,
					step: 0.05,
					numberOnlyMode: true,

					onScrubEnd:function(){

						this.state.galleryParent?._freeformEditor?.commitTransform();
	 										
					},			
				},
			]
		},
		{
			type: 'group',
			className: 'grid-columns-even',
			children: [
				{
					labelName: "Width",
					name: "freeform-scale",
					type: "scrubber",
					value: 20,
					step: 0.05,
					min: 0,
					max: 150,
					numberOnlyMode: true		
				},			

				{
					labelName: "Bring to Front",
					name: "freeform-z",
					type: "button",
					onClick: function(e){

		 				CargoEditor.mutationManager.execute(()=>{

		 					const galleryEl = this.state.selectedMediaItems[0].closest('gallery-freeform');

		 					if( galleryEl ){
								galleryEl._freeformEditor?.stackZ()		 						
			 					const galleryItems = _.sortBy(Array.from(galleryEl.querySelectorAll('media-item')), (el)=> parseInt(el.getAttribute('freeform-z') ));
								const selectedItems = this.state.selectedMediaItems;
			 					selectedItems.forEach(el=>{
									el.setAttribute('freeform-z', 1+galleryItems.length);
			 					})
								galleryEl._freeformEditor?.stackZ()		 						
		 					}

		 
		 				});

					}
				}
			]
		}
	],
	mobileOptions: [
		{
			type: 'group',
			className: 'grid-columns-auto',
			children: [	
				{
					labelName: "Use Grid",
					name: "mobile-grid",
					type: "check-box",
					value: true,
				},
			]
		},
		{
			type: 'group',
			className: 'grid-columns-auto',
			requirements: [
				{
					option: 'mobile-grid',
					shouldBe: false
				}
			],
			renderWhenDisabled: false,
			children: [	
				{
					labelName: "Magnetic Snap",
					name: "magnetic-snap-margin",
					type: "scrubber",
					value: 0,
					step: 0.05,
					suffix: '%',
					max: 10,
					min: 0,
					numberOnlyMode: true,
					requirements: [
						{
							option: 'magnetic-snap',
							shouldBe: true
						},
					],
					renderWhenDisabled: true,
				},
				{
					labelName: "",
					className: 'square',
					name: "magnetic-snap",
					type: "check-box",
					value: true,		
				},
			]
		},
		{
			type: 'group',
			className: 'grid-columns-even',
			requirements: [
				{
					option: 'mobile-grid',
					shouldBe: false
				}
			],
			renderWhenDisabled: false,
			children: [		
				{
				 	labelName: "Pop on Hover",
				 	name: "hover-pop",
				 	type: "check-box",
				 	value: false,
				},
				{
					labelName: "Fit to Width",
					name: "fitToWidth",
					type: "button",
					onClick: function(e){
						e.preventDefault();
						const galleryEl = this.state.activeGallery;
						if( !galleryEl ){
							return;
						}

						// set the zoom level in the editor 100%, then fit it
						galleryEl._freeformEditor?.setState({
							currentZoom: 100
						}, ()=>{
							galleryEl._freeformEditor?.fitToWidth();							
						})

					},
				},
			]
		},	
		{
			type: 'group',
			className: 'grid-columns-even',
			requirements: [
				{
					option: 'mobile-grid',
					shouldBe: false
				}
			],
			renderWhenDisabled: false,
			children: [	
				{
					labelName: "Shift",
					name: "horizontalShift",
					type: "scrubber",
					value: 0,
					step: 0.05,
					suffix: '%',
					max: 50,
					min: -50,
					numberOnlyMode: true,
					onScrubEnd:function(val){
						this.state.activeGallery?._freeformEditor?.commitHorizontalShift();
					},
				},
				{
					labelName: "Zoom",
					name: "zoom",
					type: "scrubber",
					value: 100,
					step: 0.1,
					suffix: '%',
					max: 200,
					min: 50,
					numberOnlyMode: true,
					onScrubEnd:function(val){
						this.state.activeGallery?._freeformEditor?.commitZoom();
					},
				},				
			]
		},
		{
			labelName: "Columns",
			name: "mobile-columns",
			type: "scrubber",
			value: 2,
			min: 1,
			max: 12,
			step: 1,
			numberOnlyMode: true,
			requirements: [
				{
					option: 'mobile-grid',
					shouldBe: true
				}
			],
			renderWhenDisabled: false,
		},
		{
		 	labelName: "Gutter",
		 	name: "mobile-gutter",
		 	type: "scrubber",
		 	addDefaultUnitToUnitlessNumber: true,
		 	value: '2rem',
		 	defaultUnit: 'rem',
		 	min: 0,
		 	max: {
				rem: 15,
				em: 20,
				px: 100,
				etc: 10,
		 	},
		 	allowCalc: true,
		 	allowedUnits: ['px', '%', 'rem', 'vmin', 'vmax', 'vw', 'vh'],
			 requirements: [
				{
					option: 'mobile-grid',
					shouldBe: true
				}
			],
			renderWhenDisabled: false,
		},
		{
			labelName: "Vertical Align",
			name: "mobile-vertical-align",
			type: "radio",
			value: "top",
			values: [
				{
					labelName: 'Top',
					iconName: 'alignContentTop',
					value: 'top'
				},
				{
					labelName: 'Middle',
					iconName: 'alignContentMiddle',
					value: 'center'
				},
				{
					labelName: 'Bottom',
					iconName: 'alignContentBottom',
					value: 'bottom'
				},
			],
			requirements: [
				{
					option: 'mobile-grid',
					shouldBe: true
				}
			],
			renderWhenDisabled: false,
		},
		{
			labelName: "Horizontal Align",
			name: "mobile-horizontal-align",
			type: "radio",
			value: "left",		
			values: [
				{
					labelName: 'Left',
					iconName: 'alignContentLeft',
					value: 'left'
				},
				{
					labelName: 'Center',
					iconName: 'alignContentCenter',
					value: 'center'
				},
				{
					labelName: 'Right',
					iconName: 'alignContentRight',
					value: 'right'
				},
			],
			requirements: [
				{
					option: 'mobile-grid',
					shouldBe: true
				}
			],
			renderWhenDisabled: false,
		},
	],
	options: [
		{
			type: 'group',
			className: 'grid-columns-auto',
			children: [	
				{
					labelName: "Magnetic Snap",
					name: "magnetic-snap-margin",
					type: "scrubber",
					value: 0,
					step: 0.05,
					suffix: '%',
					max: 10,
					min: 0,
					numberOnlyMode: true,
					requirements: [
						{
							option: 'magnetic-snap',
							shouldBe: true
						}
					],
					renderWhenDisabled: true,
				},
				{
					labelName: "",
					className: 'square',
					name: "magnetic-snap",
					type: "check-box",
					value: true				
				},
			]
		},
		{
			type: 'group',
			className: 'grid-columns-even',
			children: [		
				{
				 	labelName: "Pop on Hover",
				 	name: "hover-pop",
				 	type: "check-box",
				 	value: false,
				},
				{
					labelName: "Fit to Width",
					name: "fitToWidth",
					type: "button",
					onClick: function(e){
						e.preventDefault();
						const galleryEl = this.state.activeGallery;
						if( !galleryEl ){
							return;
						}

						// set the zoom level in the editor 100%, then fit it
						galleryEl._freeformEditor?.setState({
							currentZoom: 100
						}, ()=>{
							galleryEl._freeformEditor?.fitToWidth();							
						})

					}
				},
			]
		},	
		{
			type: 'group',
			className: 'grid-columns-even',
			children: [	
				{
					labelName: "Shift",
					name: "horizontalShift",
					type: "scrubber",
					value: 0,
					step: 0.05,
					suffix: '%',
					max: 50,
					min: -50,
					numberOnlyMode: true,
					onScrubEnd:function(val){

						this.state?.activeGallery?._freeformEditor?.commitHorizontalShift();

					},						

					
				},
				{
					labelName: "Zoom",
					name: "zoom",
					type: "scrubber",
					value: 100,
					step: 0.1,
					suffix: '%',
					max: 200,
					min: 50,
					numberOnlyMode: true,
					onScrubEnd:function(val){

						this.state?.activeGallery?._freeformEditor?.commitZoom();

					},	

				},				
			]
		},		
	],

	onMediaItemChange: function(changes){

		const galleryEl = this.state.galleryParent;
		if( !galleryEl ){
			return {...changes};
		}


		Object.keys(changes).forEach(name=>{
			const val = changes[name];
			switch(name){

				case 'shift-x':
					galleryEl._freeformEditor?.previewTransform({x: val, y: 0}, this.state.selectedMediaItems);
					break

				case 'shift-y':
					galleryEl._freeformEditor?.previewTransform({x: 0, y: val}, this.state.selectedMediaItems);
					break;	
			}

		})

		return {...changes}

	},	

	onChange: function(changes){
		const galleryEl = this.state.activeGallery;
		if( !galleryEl ){
			return;
		}		

		Object.keys(changes).forEach(name=>{
			const val = changes[name];
			switch(name){

				case 'horizontalShift':
					galleryEl._freeformEditor?.horizontalShift(val);
					break

				case 'zoom':
					galleryEl._freeformEditor?.zoom(val);
					break;	
			}

		})

	}
}

layoutData.mediaItemDefaults = helpers.collapseOptions(layoutData.mediaItemOptions);
layoutData.defaults = helpers.collapseOptions(layoutData.options);
layoutData.mobileDefaults = helpers.collapseOptions(layoutData.mobileOptions);

Freeform.defaultProps = {
	'show-tags': true,
	'show-title': true,
	'thumbnail-index': undefined,

	'magnetic-snap': layoutData.defaults['magnetic-snap'].value,
	'magnetic-snap-margin': layoutData.defaults['magnetic-snap-margin'].value,
	'hover-pop': layoutData.defaults['hover-pop'].value,
	'mobile-grid': layoutData.mobileDefaults['mobile-grid'].value,
	'mobile-gutter': layoutData.mobileDefaults['mobile-gutter'].value,
	'mobile-columns': layoutData.mobileDefaults['mobile-columns'].value,
	'mobile-horizontal-align': layoutData.mobileDefaults['mobile-horizontal-align'].value,
	'mobile-vertical-align': layoutData.mobileDefaults['mobile-vertical-align'].value,
}


export {layoutData};
export default Freeform
